const artistsList = [
  {
    name: "Julian",
    files: "Julian",
    fullName: "Julian Altier",
    location: "Kapolei",
    pic: "https://sacredarthawaii.com/images/artists/Julian.jpeg",
    about:
      "Hello my name is Julian Altier and I'm a tattoo artist from So Cal. I have background in fine line and black and grey , but my passion and love is in Traditional tattoos. My take on it is a mixture of a few things: my graffiti background and Chicano upbringing with American tradition. I love to be given artistic freedom, free handing work , drawing straight onto the skin —it helps give a better flow when I can follow curves of the body .One of my goals is to bring more color to tattoos; to break themold of old traditional. Like bringing Afrocentric and Latin flavor to traditional pinups and art. I want to share my art and love for this craft to everyone —coloring the world one person at a time! For more examples of my work visit my Instagram page below!",
    instagram: "https://www.instagram.com/xwildxlovex",
    email: "",
    facebook: "",
    url: "/gallery/julian",
  },
  {
    name: "Kliff",
    files: "Kliff",
    fullName: "Clifford Manners",
    location: "Kapolei",
    pic: "https://sacredarthawaii.com/images/artists/Kliff.jpg",
    about:
      "Aloha, my name is Clifford Manners but you can call me Kliff. I'm from Māʻili, Hawai'i. I've always been an artist from the very first time I held a pencil. I officially became a Tattoo artist back in 2020. I'm beyond grateful to be a part of this team where I'm able to learn new things and expand my skills. I enjoy tattooing black & gray, realistic animal portraits, and anime, but open to all. When I'm not tattooing or drawing... I love spending time with my Ohana. Looking forward to meeting you and bringing your dream tattoo to life! Mahalo!",
    instagram: "https://www.instagram.com/tatt2kliff",
    email: "",
    facebook: "",
    url: "/gallery/kliff",
  },
  {
    name: "Josh",
    files: "Josh",
    fullName: "Josh Crowell",
    location: "Kapolei",
    pic: "https://sacredarthawaii.com/images/artists/josh-pic.jpg",
    about:
      "Josh Crowell is an award-winning second-generation tattoo artist born in Wichita, Kansas. Josh apprenticed in Kansas City under his father who apprenticed under Bob Shaw in Texas. Tattooing since 1996 at 16 years of age. He honed his skills in a street shop from day one. Walk-in traffic helped him learn all different types and styles of tattooing. Josh moved to Hawaii in 1999 after following the yellow brick road. Josh workedat several tattoo studios along the way until he found his home at Sacred Art Tattoo Waikiki in 2010. Specialties are: American Traditional, large-scale Japanese, Polynesian, Fine-Line Black and Grey and Tourist-Trap Art. Oh yeah, we're not in Kansas anymore!!",
    instagram: "https://www.instagram.com/handmadetattooer",
    facebook: "",
    email: "",
    url: "/gallery/josh",
  },
  {
    name: "Kamaile",
    files: "kamaile",
    fullName: "Kamaile",
    location: "Kapolei",
    pic: "https://sacredarthawaii.com/images/artists/Kamaile.jpg",
    about:
      "Aloha, I’m Kamaile. I was born and raised here on O’ahu in Waimānalo. Before piercing I worked with non-verbal adults with autism, child development and at-risk youth. Piercing was always an interest of mine and was even offered an apprenticeship after graduating high school but had a scholarship to play softball at a college in Washington state. During a burnout period, I decided on reaching out for an apprenticeship where Sacred Art Tattoo gave me an opportunity. I enjoy doing ear curations, and body piercings. When I'm not at the shop I like to go paddle boarding and spend time with my family and friends! Check out my Instagram to see more of my work!",
    instagram: "https://instagram.com/emotionholes",
    facebook: "",
    email: "",
    url: "/gallery/kamaile",
  },
  {
    name: "Mino",
    files: "Mino",
    fullName: "Mino Kaneko",
    location: "Kapolei",
    pic: "https://sacredarthawaii.com/images/artists/mino.jpg",
    about:
      "Aloha! My name is Mino, a Marine with two Iraq deployments with 8.5 years of tattooing experience. First love of art is culinary. If I hadn’t signed up to be a Marine I would be in the kitchen. Hahaha, seriously, food! (Am I right?) I specialize in Polynesian tribal, geometric/mandala, fine line/minimalist work. I love bringing people’s ideas to fruition on skin.",
    instagram: "https://www.instagram.com/mino_tattoo",
    email: "",
    facebook: "https://www.facebook.com/minotattoo87/",
    url: "/gallery/mino",
  },
  {
    name: "Dan",
    files: "Dan",
    fullName: "Dan",
    location: "Kapolei",
    pic: "https://sacredarthawaii.com/images/artists/dan.jpg",
    about:
      "Meet Dan. He makes incredible tattoos in any style but is particularly drawn to American Traditional and Blackwork. He’s been Tattooing professionally for 7 years in Seattle, Denver, and Honolulu. Ask him about his cattle dog, motorbike, or book he’s reading.",
    instagram: "https://www.instagram.com/danstenderart",
    email: "",
    facebook: "",
    url: "/gallery/dan",
  },
  {
    name: "Anthony",
    files: "Anthony",
    fullName: "Anthony Tran",
    location: "Kapolei",
    pic: "https://sacredarthawaii.com/images/artists/anthony.jpg",
    about:
      "Hello everybody, I hope you’re having a wonderful day. I am Anthony Tran, born and raised in Pālolo, Hawai’i. Art has been everything in my life, from drawing Dragonball Z and painting graffiti as a hobby growing up. I love being creative with details, making clients satisfied and happy on their new tattoo. I started tattooing in Saigon Vietnam as an apprentice in 2010 , Hawai’i licensed tattoo artist in 2013, & now today I’m here with Sacred Arts to show you my progress. Thank you guys for letting me in. I specialize in mixed Asian tattoos, letterings , realism style, black & grey work, and anything you want, I’m ready. Check me out on IG! Come get a tattoo and some coffee!",
    instagram: "https://www.instagram.com/anthonytatts",
    email: "",
    facebook: "",
    url: "/gallery/anthony",
  },
  {
    name: "Ben",
    files: "Ben",
    fullName: "Ben Trail",
    location: "Kapolei",
    pic: "https://sacredarthawaii.com/images/artists/ben.jpg",
    about:
      "Aloha. My name is Ben Trail. I started my career airbrushing and doing pen art for major surfboard labels as well as fine art. I’ve been doing art professionally for about 25 years now. My art led me into tattooing and I’m passionate about the craft. I care deeply about tattooing, where it’s come from and where it’s going. I love tattooing traditional but can tattoo anything. I specialize in American Traditional, black and gray, fine line and script. I was trained in mixing and using dry pigment which is a natural, long lasting ink and can use this method if requested. Check out my instagram for more of my work!",
    instagram: "https://www.instagram.com/bentrail_art",
    email: "",
    facebook: "",
    url: "/gallery/ben",
  },
  {
    name: "Diego",
    files: "Diego",
    fullName: "Diego Palmares",
    location: "Kapolei",
    pic: "https://sacredarthawaii.com/images/artists/diego.jpg",
    about:
      "Diego T. Palmares also known as DP, Dead Pres, or his Instagram handle @thablackbradpitt is a professional tattoo artist from Meriden, Connecticut.  Diego has been an artist since childhood. His drawings and paintings were at an advanced level, even from a very early age. With the support of his family, his passion for art blossomed into work as a muralist,clothing designer, and graphic artist. The skill set he has obtained through his lifelong experiences has molded Diego into one of the most promising and up and coming artists in the game. DP has already proven himself to be a rare breed of talent, skill, and versatility. He has achieved all of his accomplishments while still appreciating the idea of growing and learning multiple tattooing styles and techniques.  Diego has experience with traditional, tribal, cover ups, black and grey, lettering, watercolor, anime,comic, fine linework, tattoo restore, and custom tattoos.  In 2020,  six years into his ten year career, Diego became a force to be reckoned with as he continues to gain success on his U.S. tour as a traveling artist. Thus far, he had made stops in Hawaii (7x) New York (3x), California, Ohio (3x), Colorado (2x) , Utah, Texas, Maryland, Massachusetts, along with owning his own shop in CT.  DP’s down to earth personality keeps him humble and allows him to continue to appreciate his great tattoo journey. In 2024, you can catch him here at Sacred Art Kapolei as he spreads positive vibes and everlasting masterpieces with his love for art and aloha spirit!",
    instagram: "https://www.instagram.com/thablackbradpitt",
    email: "",
    facebook: "",
    url: "/gallery/diego",
  },
  {
    name: "Kluch",
    files: "Shawn",
    fullName: "Kluch",
    location: "Kapolei",
    pic: "https://sacredarthawaii.com/images/artists/shawn.jpg",
    about:
      "ALOHA, EVERYONE! I'm Shawn “KLUCH” Hutto -- a 12 year tattoo artist from Pensacola, Florida. I LOVE to do color traditional, black and gray traditional, color neo traditional, B&G neo traditional, black work, tribal, cartoons, horror tattoos, and MUCH, MUCH, MORE!!! Come by and LET'S TALK TATTOOs and put something together that you will LOVE for the rest of your LIFE!!",
    instagram: "https://www.instagram.com/kluch_tattoo",
    email: "",
    facebook: "",
    url: "/gallery/kluch",
  },
];

export default artistsList;
